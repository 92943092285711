import { default as api5wy6uy1fBRMeta } from "/app/pages/api.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as _91showName_93pD6mqEiMJlMeta } from "/app/pages/shows/[showId]/[showName].vue?macro=true";
export default [
  {
    name: "Api",
    path: "/api",
    meta: api5wy6uy1fBRMeta || {},
    component: () => import("/app/pages/api.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "Privacy Policy",
    path: "/privacy",
    meta: privacyg3gwBRGXatMeta || {},
    component: () => import("/app/pages/privacy.vue")
  },
  {
    name: "show-details",
    path: "/shows/:showId()/:showName()",
    meta: _91showName_93pD6mqEiMJlMeta || {},
    component: () => import("/app/pages/shows/[showId]/[showName].vue")
  }
]