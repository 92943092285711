import revive_payload_client_ayg6PCF3ox from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_OzI621sF7d from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vite@5.4.11_@types+n_p3alkam43koh66noap46aveyfy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_io0xLgeu9A from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_OEwMUoBaEH from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VNNg9Nm8lU from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_k5bry3323Y from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5oUm4gmGXF from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KkfCphnkCr from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pfZLgywtdQ from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5dFkVAVeDI from "/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_bufferutil@4.0.8_ioredis@5.4.1_magicas_i75viwwart6ne5iqgrfexmlhcy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_i2XnAzh0XJ from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vite@5.4.11_@types+n_p3alkam43koh66noap46aveyfy/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_niomL1ltso from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typesc_pityk3jbljlssgrxa3qiahq4ji/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import cloudflare_client_l7hHizcRfX from "/app/plugins/cloudflare.client.ts";
import matomo_client_Ed2EqINkhd from "/app/plugins/matomo.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_ayg6PCF3ox,
  vuetify_icons_OzI621sF7d,
  unhead_io0xLgeu9A,
  router_OEwMUoBaEH,
  payload_client_VNNg9Nm8lU,
  navigation_repaint_client_k5bry3323Y,
  check_outdated_build_client_5oUm4gmGXF,
  chunk_reload_client_KkfCphnkCr,
  plugin_vue3_pfZLgywtdQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5dFkVAVeDI,
  vuetify_no_client_hints_i2XnAzh0XJ,
  plugin_wy0B721ODc,
  plugin_niomL1ltso,
  cloudflare_client_l7hHizcRfX,
  matomo_client_Ed2EqINkhd,
  sentry_client_shVUlIjFLk,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]